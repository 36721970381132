import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { Button, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../../hooks/store";
import { usePostResetPasswordMutation } from "../../../services/auth";
import { useState } from "react";
import {
  STORAGE_KEYS,
  errorToast,
  removeFromStorage,
  successToast,
} from "../../../helpers";
import { temporaryToken } from "../../../reducer/authSlice";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");

  const [resetPasswordMethod, resetPasswordMutation] =
    usePostResetPasswordMutation();

  const onFinish = async (values: any) => {
    const data = {
      password: values.password,
    };
    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      const res = await resetPasswordMethod(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        successToast("Password updated successfully");
        removeFromStorage(STORAGE_KEYS.tempToken);
        dispatch(
          temporaryToken({
            tempToken: null,
          })
        );
        navigate("/", {
          replace: true,
        });
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const validateConfirmPassword = ({
    getFieldValue,
  }: {
    getFieldValue: any;
  }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Passwords do not match."));
    },
  });

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="bck_arrw_div">
          <ArrowLeftOutlined
            style={{ fontSize: 20, cursor: "pointer" }}
            className="bck_arrw"
            onClick={() => navigate(-1)}
          />
          <h3>Reset Password</h3>
        </div>
        <div className="login_img">
          {/* <img src="/static/images/logo.png" alt="" /> */}
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          style={{
            marginTop: 50,
            justifyContent: "center",
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            className="pswrd"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                min: 6,
                message: "Password must include at least 6 characters",
              },
              {
                max: 15,
                message: "Password should not be more than 15 characters",
              },
            ]}
          >
            <Input.Password
              style={{ height: "50px" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              validateConfirmPassword,
            ]}
          >
            <Input.Password
              style={{  height: "50px" }}
              placeholder="Confirm Password"
            />
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  height: 50,
                  margin: "auto",
                  color: "black",
                  fontWeight: 600,
                }}
                type="primary"
                htmlType="submit"
                loading={resetPasswordMutation?.isLoading}
              >
                Reset
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ResetPassword;
