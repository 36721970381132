// @ts-nocheck
import { Button, Card, Col, DatePicker, Form, Input, Row, Upload } from "antd";
import { useNavigate } from "react-router-dom";

import { ContentHeader } from "../../components";
import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { usePutUpdateProfileMutation } from "../../services/auth";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { getToken, setCredentials } from "../../reducer/authSlice";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import {
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../helpers";
import useAuth from "../../hooks/useAuth";
import { UploadMedia } from "../../utils/mediaUpload";
import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

const EditDocuments = () => {
  dayjs.extend(weekday);
  dayjs.extend(localeData);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const token = useAppSelector(getToken);
  const dispatch = useAppDispatch();
  const userData = useAuth();

  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [docName, setDocName] = useState("");
  const [loading, setLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState<dayjs.Dayjs | null>(null);

  const [updateProfileMethod, updateProfileMutation] =
    usePutUpdateProfileMutation();

  const onFinish = async (values: any) => {
    // console.log("Received values of form: ", values);

    let data = {
      docName: values.docName,
      docFrontImage: frontImage,
      docBackImage: backImage,
      docExpireDate: expiryDate ? expiryDate.toISOString() : null,
    };
    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      const res = await updateProfileMethod(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        navigate("/dashboard", { replace: true });
        successToast("Documents updated successfully");

        setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: token || null,
          })
        );
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setExpiryDate(date);
  };

  const disabledDate = (current: Dayjs | null) => {
    return current && current.isBefore(dayjs(), "day");
  };

  const handleImageUpload = async (file: any, key: string) => {
    if (file?.type.includes("image")) {
      try {
        setLoading(true);
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (key === "front") {
            setFrontImage(res?.data);
          } else {
            setBackImage(res?.data);
          }
        }
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
      }
    } else {
      errorToast("Only images are allowed");
    }
  };

  useEffect(() => {
    if (userData) {
      setDocName(userData?.docName);
      if (userData?.docExpireDate) {
        setExpiryDate(dayjs(dayjs(userData?.docExpireDate)));
      } else {
        setExpiryDate(null);
      }
      setFrontImage(userData?.docFrontImage);
      setBackImage(userData?.docBackImage);
      form.setFieldsValue({
        docName: userData?.docName,
        frontImage: userData?.docFrontImage,
        backImage: userData?.docBackImage,
        expiryDate: userData?.docExpireDate,
      });
    }
  }, [userData]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Update Documents" />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="docName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your document name!",
                    },
                    {
                      min: 2,
                      message: "Document name must be at least 2 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Name of document</h4>
                    <Input
                      // disabled
                      style={{ height: 55 }}
                      placeholder="Name of document"
                      value={docName}
                      onChange={(e) => setDocName(e.target.value)}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="expiryDate"
                  rules={[
                    {
                      required: true,
                      message: "Please select Expiry date ",
                    },
                  ]}
                  validateTrigger={["onChange", "onBlur", "allowClear"]}
                >
                  <div>
                    <h4>Expiry Date</h4>
                    <DatePicker
                      value={expiryDate}
                      onChange={handleDateChange}
                      style={{ width: "100%", height: 55 }}
                      allowClear={false}
                      disabledDate={disabledDate}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="frontImage"
                  rules={[
                    {
                      required: true,
                      message: "Please upload front image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={(file) => handleImageUpload(file, "front")}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Front Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {frontImage ? (
                      <img
                        className="edt_prlimg"
                        src={frontImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>

              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="backImage"
                  rules={[
                    {
                      required: true,
                      message: "Please upload back image",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={(file) => handleImageUpload(file, "back")}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Back Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {backImage ? (
                      <img
                        className="edt_prlimg"
                        src={backImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item >
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={updateProfileMutation?.isLoading}
                >
                  Update Documents
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default EditDocuments;
