import { Layout, Spin } from "antd";

import "./dashboard.css";
import {
  DollarCircleOutlined,
  BoldOutlined,
  LineChartOutlined,
  AlignLeftOutlined,
  FlagOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { DashboardCard } from "../../components";
import BookingEarningGraph from "../../components/charts/BookingEarningGraph";
import { useEffect, useState } from "react";
import { useLazyGetDashboardQuery } from "../../services/common";
import { errorToast } from "../../helpers";

const Dashboard = () => {
  const { Content } = Layout;

  const [dashboardMethod] = useLazyGetDashboardQuery();

  const [dashboardData, setDashboardData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [campaignsFilter, setCampaignsFilter] = useState<string>("monthly");
  const [donationAmountFilter, setDonationAmountFilter] =
    useState<string>("monthly");
  const [campaignsNames, setCampaignsNames] = useState<string[]>([]);
  const [campaignsValues, setCampaignsValues] = useState<number[]>([]);
  const [donationAmountNames, setDonationAmountNames] = useState<string[]>([]);
  const [donationAmountValues, setDonationAmountValues] = useState<number[]>(
    []
  );

  const STATS = [
    {
      name: "Total Donation Received",
      icon: <SmileOutlined style={{ color: "green" }} />,
      number: dashboardData?.totalDonationReceived || 0,
      prefix: "#",
    },
    {
      name: "Total Campaigns",
      icon: <AlignLeftOutlined style={{ color: "rgb(255, 120, 45)" }} />,
      number: dashboardData?.totalCampaigns || 0,
      prefix: "#",
    },
    {
      name: "Active Campaigns",
      icon: <FlagOutlined style={{ color: "rgb(255, 170, 44)" }} />,
      number: dashboardData?.activeCampaigns || 0,
      prefix: "#",
    },
    {
      name: "Total Donation Amount",
      icon: <DollarCircleOutlined style={{ color: "rgb(0, 173, 163)" }} />,
      number: dashboardData?.totalDonationAmount || 0,
      prefix: "$",
    },
  ];

  const labels = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ];

  const graphData = {
    labels: campaignsNames,
    // labels,
    datasets: [
      {
        label: "Campaigns",
        data: campaignsValues || [],
        // data: [12, 43, 54, 65, 23, 12, 17, 56, 23, 78, 54, 67, 23, 45, 89],
        borderColor: "#ECAC35",
        backgroundColor: "#C69320",
      },
    ],
  };

  const graphCustomerData = {
    labels: donationAmountNames,
    // labels,
    datasets: [
      {
        label: "Donation Amount",
        data: donationAmountValues || [],
        // data: [12, 43, 54, 65, 23, 12, 17, 56, 23, 78, 54, 67, 23, 45, 89],
        borderColor: "#ECAC35",
        backgroundColor: "#C69320",
      },
    ],
  };

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const res = await dashboardMethod({
        bookingFilter: campaignsFilter,
        revenueFilter: donationAmountFilter,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setDashboardData(res?.data);
        setCampaignsNames(Object.keys(res?.data?.totalCampaignsGraph));
        setCampaignsValues(Object.values(res?.data?.totalCampaignsGraph));
        setDonationAmountNames(
          Object.keys(res?.data?.totalDonationAmountGraph)
        );
        setDonationAmountValues(
          Object.values(res?.data?.totalDonationAmountGraph)
        );
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, [campaignsFilter, donationAmountFilter]);

  return (
    <Content
      style={{
        margin: "0px 10px",
        padding: 35,
        minHeight: 280,
      }}
    >
      <Spin spinning={loading}>
        <div style={{ fontSize: 18 }}>
          <h1>Dashboard</h1>
        </div>

        <div className="top_bar">
          {STATS.map((item, index) => (
            <DashboardCard data={item} key={index} />
          ))}
        </div>

        <div
          style={{
            margin: "20px 0px",
          }}
        >
          <BookingEarningGraph
            graphData={graphData}
            graphCustomerData={graphCustomerData}
            campaignsFilter={campaignsFilter}
            setCampaignsFilter={setCampaignsFilter}
            donationAmountFilter={donationAmountFilter}
            setDonationAmountFilter={setDonationAmountFilter}
          />
        </div>
      </Spin>
    </Content>
  );
};

export default Dashboard;
