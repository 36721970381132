import { END_POINTS } from "../helpers";
import { CommonBody, Category } from "../types/General";
import type { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: User;
  message: string;
};

type CategoryResponse = {
  statusCode: number;
  message: string;
};

type PostForgotPasswordResponse = {
  statusCode: number;
  data?: User;
  message: string;
};

type PostVerifyOtpResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PostResetPasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

type PostChangePasswordResponse = {
  statusCode: number;
  data?: any;
  message: string;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),

    postSignUp: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.donationSignup,
        method: "POST",
        body,
      }),
    }),

    postForgotPassword: builder.mutation<
      PostForgotPasswordResponse,
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),

    postVerifyOtp: builder.mutation<PostVerifyOtpResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.verifyOtp,
        method: "POST",
        body,
      }),
    }),
    putUpdateProfile: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.updateProfile,
        method: "PUT",
        body,
      }),
    }),
    postResetPassword: builder.mutation<PostResetPasswordResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),
    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
    getProfile: builder.query<CommonResponseType, {}>({
      query: () => ({
        url: END_POINTS.getProfile,
        method: "GET",
      }),
    }),

    getDonationCategory: builder.query<
      CategoryResponse & {
        data?: {
          count: number;
          pages: number;
          donationCategory: Category[] | [];
        };
      },
      { page: number; size: number; search: string }
    >({
      query: ({ page, size, search }) => ({
        url:
          END_POINTS.donationCategory +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          search,
        method: "GET",
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
  usePostResetPasswordMutation,
  usePutUpdateProfileMutation,
  useLazyGetProfileQuery,
  usePostChangePasswordMutation,
  usePostSignUpMutation,
  useLazyGetDonationCategoryQuery,
} = authApi;
