import { useEffect, useState } from "react";
import {
  UserOutlined,
  PieChartOutlined,
  SettingOutlined,
  BellOutlined,
  BoldOutlined,
  AlignLeftOutlined,
  LineChartOutlined,
  MoneyCollectOutlined,
  FlagOutlined,
  HeartOutlined,
  ContactsFilled,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import "./layout.css";
import { useNavigate, useLocation } from "react-router-dom";

const { Sider } = Layout;

const SiderBar = ({ collapsed }: { collapsed: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = useState("1");

  useEffect(() => {
    if (location?.pathname?.includes("dashboard")) {
      setSelectedKey("1");
    } else if (location?.pathname?.includes("manage-campaign")) {
      setSelectedKey("2");
    } else if (location?.pathname?.includes("donations")) {
      setSelectedKey("3");
    } else if (location?.pathname?.includes("notifications")) {
      setSelectedKey("7");
    } else if (location?.pathname?.includes("support")) {
      setSelectedKey("9");
    } else {
      setSelectedKey("");
    }
  }, [location]);

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        {/* <img src={"/static/images/logo.png"} className="logo_img" alt="" /> */}
      </div>
      <Menu
        style={{ marginTop: 16 }}
        theme="dark"
        mode="inline"
        className="side_menu"
        defaultSelectedKeys={[selectedKey]}
        selectedKeys={[selectedKey]}
        items={[
          {
            key: "1",
            icon: <PieChartOutlined />,
            label: "Dashboard",
            onClick: () => navigate("/dashboard"),
          },

          {
            key: "2",
            icon: <FlagOutlined />,
            label: "Manage Campaign",
            onClick: () => navigate("/manage-campaign"),
          },

          {
            key: "3",
            icon: <HeartOutlined />,
            label: "Donations",
            onClick: () => navigate("/donations"),
          },
          {
            key: "7",
            icon: <BellOutlined />,
            label: "Notification",
            onClick: () => navigate("/notifications"),
          },
          {
            key: "9",
            icon: <ContactsFilled />,
            label: "Support",
            onClick: () => navigate("/support"),
          },
        ]}
      />
    </Sider>
  );
};

export default SiderBar;
