import { useEffect, useState } from "react";
import AuthWrapper from "../../../layout/authWrapper";
import "./login.css";
import { Button, Card, Checkbox, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { usePostLoginMutation } from "../../../services/auth";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../../helpers";
import { setCredentials, temporaryToken } from "../../../reducer/authSlice";
import { useAppDispatch } from "../../../hooks/store";

const Login = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [isEmail, setIsEmail] = useState(true);
  const [phoneCode, setPhoneCode] = useState("+1");
  const [countryName, setCountryName] = useState("us");
  const [phoneNo, setPhoneNo] = useState("");
  const [isRemember, setIsRemember] = useState(false);
  const [error, setError] = useState(false);

  const [loginMethod, loginMutation] = usePostLoginMutation();

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const onFinish = async (values: any) => {
    const fcmToken = await getFromStorage(STORAGE_KEYS.fcmToken);
    setError(false);

    if (!isEmail) {
      if (!phoneNo) {
        setError(true);
        return;
      }
      if (phoneNo?.length < 10) {
        setError(true);
        return;
      }
    }
    try {
      let data = {};
      if (isEmail) {
        data = {
          key: values.email,
          password: values.password,
          deviceType: "WEB",
          deviceToken: fcmToken || "donation@123",
          role: "DONATIONADMIN",
        };
      } else {
        data = {
          key: phoneNo,
          countryCode: phoneCode?.includes("+") ? phoneCode : `+${phoneCode}`,
          countryName: countryName,
          role: "DONATIONADMIN",
        };
      }

      console.log(data, "body login api");

      if (isRemember) {
        setToStorage(STORAGE_KEYS.credentials, JSON.stringify(data));
      } else {
        removeFromStorage(STORAGE_KEYS.credentials);
      }

      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await loginMethod(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        if (isEmail) {
          if (res?.data && res?.data?.docsUploaded) {
            setToStorage(STORAGE_KEYS.token, JSON.stringify(res?.data?.token));
            setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
            dispatch(
              setCredentials({
                user: res?.data || null,
                token: res?.data?.token || null,
              })
            );
            navigate("/dashboard", { replace: true });
          } else {
            setToStorage(
              STORAGE_KEYS.tempToken,
              JSON.stringify(res?.data?.token)
            );
            setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
            dispatch(
              temporaryToken({
                tempToken: res?.data?.token || null,
              })
            );
            navigate("/profile", {
              state: {
                replace: true,
                data: res?.data,
              },
            });
          }
        } else {
          navigate("/verify-otp", {
            state: {
              type: "login",
              key: phoneNo,
              countryCode: phoneCode,
              replace: true,
            },
          });
        }
      }
    } catch (error: any) {
      console.log(error);

      errorToast(error?.data?.message || "");
    }
  };

  const onSelectChange = async () => {
    const creds = getFromStorage(STORAGE_KEYS.credentials);

    if (creds) {
      const credentials = await JSON.parse(`${creds}`);

      if (credentials?.countryCode) {
        form.setFieldsValue({
          phone: credentials?.key,
        });
        setPhoneNo(credentials?.key);
        setPhoneCode(credentials?.countryCode);
        setIsEmail(false);
      } else {
        form.setFieldsValue({
          email: credentials?.key,
          password: credentials?.password,
        });
        setIsEmail(true);
      }
      setIsRemember(true);
    }
  };

  useEffect(() => {
    onSelectChange();
  }, []);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="bck_arrw_div">
          <h3>Login</h3>
        </div>
        <h4 className="title">Dasooq</h4>
        <div className="login_img">
          {/* <img src="/static/images/logo.png" alt="" /> */}
        </div>
        <Form
          name="basic"
          form={form}
          labelCol={{ span: 8 }}
          style={{
            marginTop: 50,
            justifyContent: "center",
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {isEmail ? (
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "The input is not valid email!",
                },
              ]}
            >
              <Input
                style={{height: "50px" }}
                placeholder="Email"
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="phone"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your phone number!",
              //   },
              //   {
              //     min: 10,
              //     message: "Phone number must contain at least 7 numbers",
              //   },
              // ]}
            >
              <div
                style={{height: "50px" }}
                className="phn_cs"
              >
                <PhoneInput
                  value={phoneCode + phoneNo}
                  enableSearch={true}
                  country={"us"}
                  placeholder="Phone number"
                  containerClass={"cntn_telCls"}
                  inputClass={"cstm_cls"}
                  buttonClass={"custm_btn"}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                />
              </div>
              {error && !phoneNo ? (
                <h3 className="error_msg">Please input your phone number!</h3>
              ) : null}
              {error && phoneNo?.length && phoneNo?.length < 10 ? (
                <h3 className="error_msg">Please input valid phone number!</h3>
              ) : null}
            </Form.Item>
          )}

          {isEmail ? (
            <Form.Item
              // label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
                {
                  min: 6,
                  message: "Password must include at least 6 characters",
                },
                {
                  max: 15,
                  message: "Password should not be more than 15 characters",
                },
              ]}
            >
              <Input.Password
                style={{height: "50px" }}
                placeholder="Password"
                maxLength={15}
              />
            </Form.Item>
          ) : null}

          {isEmail ? (
            <Form.Item name="remember" valuePropName="checked">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Checkbox
                  onChange={() => setIsRemember(!isRemember)}
                  checked={isRemember}
                >
                  Remember me
                </Checkbox>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Forgot password?
                </div>
              </div>
            </Form.Item>
          ) : undefined}

          <Form.Item>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={loginMutation?.isLoading}
                onClick={() => setError(true)}
              >
                Login
              </Button>
            </div>
          </Form.Item>
          <div
            style={{
              cursor: "pointer",
              fontWeight: 600,
              textAlign: "center",
            }}
            onClick={() => {
              setIsEmail(!isEmail);
            }}
          >
            {isEmail ? "Login with Phone Number" : "Login with Email Address"}
          </div>
        </Form>

        <div
          style={{
            cursor: "pointer",
            fontWeight: 600,
            textAlign: "center",
            paddingTop: "10px",
          }}
        >
          Don’t have an account ?{" "}
          <span onClick={() => navigate("/sign-up")}>{"Sign up"}</span>
        </div>
      </Card>
    </AuthWrapper>
  );
};

export default Login;
