import { END_POINTS } from "../helpers";
import { Campaign, CommonBody } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: {
    donation: Campaign[];
    count: number;
  };
  message: string;
};

type EditParams = {
  body: CommonBody;
  campaignId: string | undefined;
};

export const campaignApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addCampaign: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.campaign,
        method: "POST",
        body,
      }),
    }),

    editCampaign: builder.mutation<CommonResponseType, EditParams>({
      query: ({ body, campaignId }) => ({
        url: `${END_POINTS.campaign}/${campaignId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteCampaign: builder.mutation<
      CommonResponseType,
      { campaignId: string }
    >({
      query: ({ campaignId }) => ({
        url: `${END_POINTS.campaign}/${campaignId}`,
        method: "DELETE",
      }),
    }),

    getAllCampaign: builder.query<
      CommonResponseType,
      { page: number; query: string }
    >({
      query: ({ page, query }) => ({
        url: `${END_POINTS.campaign}?page=${page}&size=10&search=${query}&all=false`,
        method: "GET",
      }),
    }),

    getCampaignById: builder.query<
      CommonResponseType & { data: Campaign },
      { campaignId: string }
    >({
      query: ({ campaignId }) => ({
        url: `${END_POINTS.campaign}/${campaignId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useAddCampaignMutation,
  useEditCampaignMutation,
  useDeleteCampaignMutation,
  useLazyGetAllCampaignQuery,
  useLazyGetCampaignByIdQuery,
} = campaignApi;
