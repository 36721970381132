import { Card, Col, Row, Select } from "antd";
import { LineChart } from ".";

const BookingEarningGraph = (props: any) => {
  return (
    <div style={{ padding: 0 }} className="grph_area">
      <Row gutter={16}>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Card className="chart_card">
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <h2 style={{ margin: 0, fontSize: 19 }}></h2>
              <div style={{ width: "20%" }}>
                <Select
                  style={{ width: "100%" }}
                  onChange={(val) => props.setCampaignsFilter(val)}
                  value={props.campaignsFilter}
                  placeholder="Filter"
                  options={[
                    { value: "daily", label: "Daily" },
                    { value: "weekly", label: "Weekly" },
                    { value: "monthly", label: "Monthly" },
                    { value: "yearly", label: "Yearly" },
                  ]}
                />
              </div>
            </div>
            <div style={{ margin: "auto" }}>
              <LineChart data={props.graphData} />
            </div>
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Card className="chart_card">
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <h2 style={{ margin: 0, fontSize: 19 }}>Total Donation Amount</h2>
              <div style={{ width: "20%" }}>
                <Select
                  style={{ width: "100%" }}
                  onChange={(val) => props.setDonationAmountFilter(val)}
                  value={props.donationAmountFilter}
                  placeholder="Filter"
                  options={[
                    { value: "daily", label: "Daily" },
                    { value: "weekly", label: "Weekly" },
                    { value: "monthly", label: "Monthly" },
                    { value: "yearly", label: "Yearly" },
                  ]}
                />
              </div>
            </div>
            <div style={{ margin: "auto" }}>
              <LineChart data={props.graphCustomerData} />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BookingEarningGraph;
