import { END_POINTS } from "../helpers";
import { Cms, NotifData } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: {
    notificationCount: number;
    notification: NotifData[];
  };
  message: string;
};

export const commonApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query<CommonResponseType, { page: number }>({
      query: ({ page }) => ({
        url: `${END_POINTS.notification}?page=${page}&size=10`,
        method: "GET",
      }),
    }),

    getDashboard: builder.query<
      CommonResponseType & { data: any },
      { bookingFilter: string; revenueFilter: string }
    >({
      query: ({ bookingFilter, revenueFilter }) => ({
        url: `${END_POINTS.dashboard}?compaignFilter=${bookingFilter}&revenueFilter=${revenueFilter}`,
        method: "GET",
      }),
    }),

    getCms: builder.query<CommonResponseType & { data: Cms }, {}>({
      query: () => ({
        url: `${END_POINTS.cms}?panel=donation`,
        method: "GET",
      }),
    }),

    logoutApi: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.logout}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllNotificationsQuery,
  useLazyGetDashboardQuery,
  useLazyGetCmsQuery,
  useLazyLogoutApiQuery,
} = commonApi;
