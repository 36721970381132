import { Button, Card, Col, Row, DatePicker, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { errorToast } from "../../../helpers";
import { useLazyGetCampaignByIdQuery } from "../../../services/campaign";
import { useEffect, useState } from "react";
import { Campaign } from "../../../types/General";
import moment from "moment";

const CampaignDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [campaignDetail, setCampaignDetail] = useState<Campaign>();

  const [getCampaignMethod, getCampaignMutation] =
    useLazyGetCampaignByIdQuery();

  const getCampaignDetail = async () => {
    try {
      if (id) {
        const res = await getCampaignMethod({ campaignId: id }).unwrap();
        if (res?.statusCode === 200) {
          setCampaignDetail(res?.data);
        }
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    getCampaignDetail();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={getCampaignMutation.isLoading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Campaign Detail</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/manage-campaign", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Campaign image</h4>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      height: 100,
                      borderRadius: 10,
                    }}
                    src={campaignDetail?.image || "/static/images/profile.png"}
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Campaign Title</h4>
                <h5>{campaignDetail?.name || ""}</h5>
              </Col>

              <Col xs={24} md={12}>
                <h4>Address</h4>
                <h5>{campaignDetail?.address || ""}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Start Date</h4>
                <h5>
                  {moment(campaignDetail?.startTime).format("YYYY-MM-DD")}
                </h5>
              </Col>
              <Col xs={24} md={12}>
                <h4>End Date</h4>
                <h5>{moment(campaignDetail?.endTime).format("YYYY-MM-DD")}</h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Description</h4>
                <h5>{campaignDetail?.description || ""}</h5>
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default CampaignDetails;
