import { useEffect, useState } from "react";
import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { Button, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { usePostSignUpMutation } from "../../../services/auth";
import { STORAGE_KEYS, errorToast, removeFromStorage } from "../../../helpers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const SignUp = () => {
  const navigate = useNavigate();
  const [isEmail, setIsEmail] = useState(true);
  const [phoneCode, setPhoneCode] = useState("+1");
  const [phoneNo, setPhoneNo] = useState("");
  const [countryName, setCountryName] = useState("us");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const [signUpMethod, signUpMutation] = usePostSignUpMutation();

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const onFinish = async (values: any) => {
    // console.log("Received values of form: ", values);
    setError(false);
    if (!isEmail) {
      if (!phoneNo) {
        setError(true);
        return;
      }
      if (phoneNo?.length < 10) {
        setError(true);
        return;
      }
    }
    try {
      let data = {};
      if (isEmail) {
        data = {
          key: values.email,
          role: "SUBADMIN",
          password: values.password,
        };
      } else {
        data = {
          key: phoneNo,
          role: "SUBADMIN",
          countryCode: phoneCode?.includes("+") ? phoneCode : `+${phoneCode}`,
          countryName: countryName,
        };
      }

      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await signUpMethod(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        navigate("/verify-otp", {
          state: {
            type: "signUp",
            key: isEmail ? values.email : phoneNo,
            countryCode: isEmail ? "" : phoneCode,
            replace: true,
          },
        });
      }
    } catch (error: any) {
      console.log(error);

      errorToast(error?.data?.message || "");
    }
  };
  const validateConfirmPassword = ({
    getFieldValue,
  }: {
    getFieldValue: any;
  }) => ({
    validator(_: any, value: any) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Passwords do not match."));
    },
  });

  useEffect(() => {
    removeFromStorage(STORAGE_KEYS.token);
    removeFromStorage(STORAGE_KEYS.userData);
    removeFromStorage(STORAGE_KEYS.tempToken);
  }, []);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="bck_arrw_div">
          <h3>Sign Up</h3>
        </div>
        {/* <h3>Sign up</h3> */}
        <div className="login_img">
          {/* <img src="/static/images/logo.png" alt="" /> */}
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          style={{
            marginTop: 50,
            // display: "flex",
            justifyContent: "center",
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {isEmail ? (
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
                {
                  type: "email",
                  message: "The input is not valid email!",
                },
              ]}
            >
              <Input
                style={{ height: "50px" }}
                placeholder="Email"
              />
            </Form.Item>
          ) : (
            <Form.Item
              name="phone"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your phone number!",
              //   },
              //   {
              //     min: 10,
              //     message: "Phone number must contain at least 7 numbers",
              //   },
              // ]}
            >
              <div
                style={{ height: "50px" }}
                className="phn_cs"
              >
                <PhoneInput
                  value={phoneCode + phoneNo}
                  enableSearch={true}
                  country={"us"}
                  placeholder="Phone number"
                  containerClass={"cntn_telCls"}
                  inputClass={"cstm_cls"}
                  buttonClass={"custm_btn"}
                  onChange={(phone, country) =>
                    handleChangePhone(phone, country)
                  }
                />
              </div>
              {error && !phoneNo ? (
                <h3 className="error_msg">Please input your phone number!</h3>
              ) : null}
              {error && phoneNo?.length && phoneNo?.length < 10 ? (
                <h3 className="error_msg">Please input valid phone number!</h3>
              ) : null}
            </Form.Item>
          )}

          {isEmail ? (
            <>
              <Form.Item
                // label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  {
                    min: 6,
                    message: "Password must include at least 6 characters",
                  },
                  {
                    max: 15,
                    message: "Password should not be more than 15 characters",
                  },
                ]}
              >
                <Input.Password
                  style={{ height: "50px" }}
                  maxLength={15}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  validateConfirmPassword,
                ]}
              >
                <Input.Password
                  style={{ height: "50px" }}
                  placeholder="Confirm Password"
                />
              </Form.Item>
            </>
          ) : null}

          <Form.Item>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  height: 50,
                  margin: "auto",
                }}
                type="primary"
                htmlType="submit"
                loading={signUpMutation?.isLoading}
                onClick={() => setError(true)}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
          <div
            style={{
              cursor: "pointer",
              fontWeight: 600,
              textAlign: "center",
            }}
            onClick={() => {
              setIsEmail(!isEmail);
            }}
          >
            {isEmail
              ? "Sign up with Phone Number"
              : "Sign up with Email Address"}
          </div>
        </Form>

        <div
          style={{
            cursor: "pointer",
            fontWeight: 600,
            textAlign: "center",
            paddingTop: "10px",
          }}
          onClick={() => navigate("/", { replace: true })}
        >
          Already have an account ? <span>{"Sign in"}</span>
        </div>
      </Card>
    </AuthWrapper>
  );
};

export default SignUp;
