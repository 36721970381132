// @ts-nocheck
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Upload,
} from "antd";
import type { DatePickerProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UploadOutlined } from "@ant-design/icons";
import HeaderBar from "../../../layout/header";
import { ContentHeader } from "../../../components";
import { RcFile } from "antd/es/upload";
import { UploadMedia } from "../../../utils/mediaUpload";
import { Autocomplete } from "@react-google-maps/api";

import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  successToast,
} from "../../../helpers";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import {
  usePostForgotPasswordMutation,
  usePutUpdateProfileMutation,
} from "../../../services/auth";
import { useLazyGetDonationCategoryQuery } from "../../../services/auth";
import moment from "moment";
import { temporaryToken } from "../../../reducer/authSlice";
import { useAppDispatch } from "../../../hooks/store";
import dayjs, { Dayjs } from "dayjs";
import { Category } from "../../../types/General";
import { isString } from "../../../utils/validations";

const { Option } = Select;

const ProfileSetUp = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const [getCategory] = useLazyGetDonationCategoryQuery();
  const [forgotPasswordMethod] = usePostForgotPasswordMutation();
  const userData = getFromStorage(STORAGE_KEYS.userData);

  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [email, setEmail] = useState("");
  const [countryName, setCountryName] = useState("us");
  const [phoneCode, setPhoneCode] = useState("+1");
  const [phoneNo, setPhoneNo] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isEmailVerify, setIsEmailVerify] = useState(false);
  const [isPhoneVerify, setIsPhoneVerify] = useState(false);
  const [error, setError] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [location, setLocation] = useState("");

  const [updateProfileMethod] = usePutUpdateProfileMutation();
  const [allCategoryData, setAllCategoryData] = useState<Category[]>([]);

  const onKeypress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async (e, val) => {
    if (autocomplete) {
      var place = await autocomplete.getPlace();
      if (place) {
        form.setFieldsValue({
          address: place.formatted_address,
        });
        setLocation(place.formatted_address);
        // Additionally, you can set lat and long here if needed
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLat(lat);
        setLong(lng);
      }
    }
  };

  const onFinish = async (values: any) => {
    // console.log("Received values of form: ", values);

    setError(false);

    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 10) {
      setError(true);
      return;
    }

    const data: {
      name: string;
      email: string;
      countryCode: string;
      phone: string;
      about: string;
      image?: string;
      docName: string;
      docFrontImage: string;
      docBackImage: string;
      docExpireDate: string;
      donationCategory: string;
      address: string;
      latitude: string;
      longitude: string;
    } = {
      email: values.email,
      name: values.organizationName,
      phone: phoneNo,
      countryCode: phoneCode?.includes("+") ? phoneCode : `+${phoneCode}`,
      countryName: countryName,
      // image: profileImage || "",
      about: values.about,
      docName: values.docName,
      docFrontImage: frontImage,
      docBackImage: backImage,
      docExpireDate: expiryDate,
      donationCategory: selectedCategory,
      docsUploaded: true,
      address: values.address,
      latitude: lat?.toString() || "76.804461",
      longitude: long?.toString() || "30.727297",
    };
    if (profileImage) {
      data.image = profileImage;
    }

    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      const res = await updateProfileMethod(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        removeFromStorage(STORAGE_KEYS.tempToken);
        dispatch(
          temporaryToken({
            tempToken: null,
          })
        );
        if (res?.data?.isEmailVerify) {
          successToast(
            "Profile created successfully! Please wait until admin approves your document"
          );
          navigate("/", { replace: true });
        } else {
          let body = {
            email: values.email,
          };

          const encryptedData = generateEncryptedKeyBody(body) as CommonBody;
          setLoading(true);
          const response = await forgotPasswordMethod(encryptedData).unwrap();
          setLoading(false);
          if (response?.statusCode === 200) {
            navigate("/verify-otp", {
              state: {
                type: "profile",
                key: res?.data?.isPhoneVerify ? values.email : phoneNo,
                countryCode: res?.data?.isPhoneVerify ? "" : phoneCode,
                replace: true,
              },
            });
          }
        }
      } else {
        setLoading(false);
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const handleDatePickerChange: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    if (date) {
      const parsedDate = moment(dateString).toISOString();

      setExpiryDate(parsedDate);
      form.setFieldsValue({
        expiryDate: parsedDate,
      });
    } else {
      setExpiryDate("");
    }
  };

  const disabledDate = (current: Dayjs | null) => {
    return current && current.isBefore(dayjs(), "day");
  };

  const handleImageUpload = async (file: RcFile, key: string) => {
    if (file?.type.includes("image")) {
      try {
        setLoading(true);
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (key === "front") {
            setFrontImage(res?.data);
          } else if (key === "profile") {
            setProfileImage(res?.data);
          } else {
            setBackImage(res?.data);
          }
        }
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
      }
    } else {
      errorToast("Only images are allowed");
    }
  };

  const getCategoryDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getCategory({
        page: page,
        size: 10,
        search: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllCategoryData(res?.data?.donationCategory);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      categoryOfTheProduct: value,
    });
    setSelectedCategory(value);

    form.validateFields(["categoryOfTheProduct"]);
  };

  useEffect(() => {
    if (userData) {
      const data = JSON.parse(userData);
      setPhoneCode(data?.countryCode || "+1");
      setPhoneNo(data?.phone || "");
      setEmail(data?.email || "");
      setCountryName(data?.countryName);
      setIsEmailVerify(data?.isEmailVerify);
      setIsPhoneVerify(data?.isPhoneVerify);
      form.setFieldsValue({
        email: data?.email,
        phone: data?.phone,
        donationCategory: selectedCategory,
      });
    }
  }, [userData, selectedCategory]);

  useEffect(() => {
    const temporary = getFromStorage(STORAGE_KEYS.tempToken);

    if (temporary) {
      dispatch(
        temporaryToken({
          tempToken: `${JSON.parse(temporary)}` || null,
        })
      );
      getCategoryDetails(1);
    }
  }, [getFromStorage]);

  return (
    <Layout>
      <Layout className="site-layout">
        {/* <HeaderBar collapsed={collapsed} setCollapsed={setCollapsed} /> */}
        <div style={{ padding: 50 }}>
          <ContentHeader heading="Update Profile" bread={true} />
          <Card>
            <div>
              <Form
                name="basic"
                layout="vertical"
                form={form}
                style={{
                  maxWidth: "80%",
                  marginTop: 10,
                }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <h4>Upload Banner Image</h4>
                    <Form.Item name="profileImage">
                      <Upload
                        beforeUpload={(file) =>
                          handleImageUpload(file, "profile")
                        }
                        showUploadList={false}
                        className="upload_imgD"
                        accept="image/*"
                      >
                        <div>
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </div>
                        {profileImage ? (
                          <img
                            className="img_profile"
                            src={profileImage}
                            alt="profile"
                          />
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <h4>Organization Name</h4>
                    <Form.Item
                      name="organizationName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Organization name!",
                          whitespace: true,
                        },
                        {
                          min: 3,
                          message: "Name must be at least 3 characters",
                        },
                        {
                          max: 20,
                          message: "Name must be at most 20 characters",
                        },
                      ]}
                    >
                      <div>
                        <Input
                          placeholder="Organization Name"
                          style={{ height: 55 }}
                          value={orgName}
                          onChange={(e) => {
                            if (isString(e.target.value)) {
                              setOrgName(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <h4>Select Category</h4>
                    <Form.Item
                      // label="Username"
                      name="donationCategory"
                      rules={[
                        {
                          required: true,
                          message: "Please select Category of Donation",
                        },
                      ]}
                    >
                      <div>
                        <Select
                          style={{ height: "100%" }}
                          allowClear
                          maxLength={20}
                          onChange={(val) =>
                            handleChangeForSelectedCategory(val)
                          }
                          placeholder={"Select Category"}
                          // style={{ width: 120 }}
                          optionFilterProp="label"
                          showSearch
                          filterOption={(input, option) =>
                            (
                              option?.label?.toString()?.toLowerCase() || ""
                            ).indexOf(input.toLowerCase()) !== -1
                          }
                        >
                          {allCategoryData?.map((item, index) => (
                            <Option
                              key={index}
                              value={item?._id}
                              label={item?.name}
                            >
                              {item?.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <h4>Email</h4>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                      ]}
                    >
                      <div>
                        <Input
                          disabled={isEmailVerify}
                          style={{ height: 55 }}
                          placeholder="E-mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <h4>Phone Number</h4>
                    <Form.Item
                      name="phone"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input your phone number!",
                      //   },
                      // ]}
                    >
                      <div>
                        <div className="phn_cs">
                          <PhoneInput
                            value={phoneCode + phoneNo}
                            enableSearch={true}
                            country={"us"}
                            // disableCountryCode={userData?.isPhoneVerify}
                            disabled={isPhoneVerify}
                            placeholder="Phone number"
                            containerClass={"cntn_telCls"}
                            inputClass={"cstm_cls"}
                            buttonClass={"custm_btn"}
                            onChange={(phone, country) =>
                              handleChangePhone(phone, country)
                            }
                          />
                        </div>
                      </div>
                      {error && !phoneNo ? (
                        <h3 className="error_msg">
                          Please input your phone number!
                        </h3>
                      ) : null}
                      {error && phoneNo?.length && phoneNo?.length < 10 ? (
                        <h3 className="error_msg">
                          Please input valid phone number!
                        </h3>
                      ) : null}
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <h4>About</h4>
                    <Form.Item
                      name="about"
                      rules={[
                        {
                          required: true,
                          message: "Please input your description!",
                        },
                        {
                          min: 10,
                          message: "Description must be at least 10 characters",
                        },
                      ]}
                    >
                      <div>
                        <Input placeholder="About" style={{ height: 55 }} />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <h4>Address</h4>
                    <Form.Item
                      name="address"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Address!",
                          whitespace: true,
                        },
                        {
                          min: 3,
                          message: "Address must be at least 3 characters",
                        },
                      ]}
                    >
                      <div>
                        <Autocomplete
                          onLoad={onLoad}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <Input
                            placeholder="Address"
                            value={location}
                            style={{ height: 55 }}
                            onKeyDown={onKeypress}
                            // value={address}
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                          />
                        </Autocomplete>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <h3>Upload Document</h3>
                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <h4>Name of document</h4>
                    <Form.Item
                      name="docName"
                      rules={[
                        {
                          required: true,
                          message: "Please input document name",
                        },
                        {
                          min: 2,
                          message:
                            "Document name must be at least 2 characters",
                        },
                      ]}
                    >
                      <div>
                        <Input
                          // disabled
                          style={{ height: 55 }}
                          placeholder="Name of document"
                        />
                      </div>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <h4>Expiry Date</h4>
                    <Form.Item
                      name="expiryDate"
                      rules={[
                        {
                          required: true,
                          message: "Please select expiry date",
                        },
                      ]}
                      validateTrigger={["onChange", "onBlur", "allowClear"]}
                    >
                      <div>
                        <DatePicker
                          style={{ width: "100%", height: 55 }}
                          onChange={handleDatePickerChange}
                          format="YYYY-MM-DD"
                          allowClear={false}
                          disabledDate={disabledDate}
                        />
                      </div>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col xs={24} md={12}>
                    <h4>Upload Front Image</h4>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please upload front image",
                        },
                      ]}
                      name="frontImage"
                    >
                      <Upload
                        beforeUpload={(file) =>
                          handleImageUpload(file, "front")
                        }
                        showUploadList={false}
                        className="upload_imgD"
                        accept="image/*"
                      >
                        <div>
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </div>
                        {frontImage ? (
                          <img
                            className="edt_prlimg"
                            src={frontImage}
                            alt="profile"
                          />
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={12}>
                    <h4>Upload Back Image</h4>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please upload back image",
                        },
                      ]}
                      name="backImage"
                    >
                      <Upload
                        beforeUpload={(file) => handleImageUpload(file, "back")}
                        showUploadList={false}
                        className="upload_imgD"
                        accept="image/*"
                      >
                        <div>
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </div>
                        {backImage ? (
                          <img
                            className="edt_prlimg"
                            src={backImage}
                            alt="profile"
                          />
                        ) : (
                          <></>
                        )}
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
                  <div style={{ display: "flex" }}>
                    <Button
                      style={{
                        background: "#ECAC35",
                        width: 200,
                        height: 50,
                        margin: "auto",
                        color: "black",
                      }}
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      onClick={() => setError(true)}
                    >
                      Submit
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Card>
        </div>
      </Layout>
    </Layout>
  );
};

export default ProfileSetUp;
