// @ts-nocheck
import { Button, Card, Col, Form, Input, Row, Select, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UploadOutlined } from "@ant-design/icons";
import { ContentHeader } from "../../components";
import useAuth from "../../hooks/useAuth";
import { RcFile } from "antd/es/upload";
import { Autocomplete } from "@react-google-maps/api";
import {
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../helpers";
import { UploadMedia } from "../../utils/mediaUpload";
import { usePutUpdateProfileMutation } from "../../services/auth";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { getToken, setCredentials } from "../../reducer/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { useLazyGetDonationCategoryQuery } from "../../services/auth";
import { Category } from "../../types/General";
import { isString } from "../../utils/validations";

const Profile = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [getCategory] = useLazyGetDonationCategoryQuery();

  const userData = useAuth();

  const token = useAppSelector(getToken);

  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryName, setCountryName] = useState("us");
  const [phoneCode, setPhoneCode] = useState("+1");
  const [phoneNo, setPhoneNo] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    organizationName: "",
    about: "",
    email: "",
    address: "",
  });
  const [location, setLocation] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const [allCategoryData, setAllCategoryData] = useState<
    Category[] | undefined
  >([]);
  const { Option } = Select;

  const [updateProfileMethod, updateProfileMutation] =
    usePutUpdateProfileMutation();

  const onKeypress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async (e, val) => {
    if (autocomplete) {
      var place = await autocomplete.getPlace();
      if (place) {
        form.setFieldsValue({
          address: place.formatted_address,
        });
        setLocation(place.formatted_address);
        // Additionally, you can set lat and long here if needed
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLat(lat);
        setLong(lng);
      }
    }
  };

  const onFinish = async (values: any) => {
    // console.log("Received values of form: ", values);

    setError(false);

    if (!phoneNo) {
      setError(true);
      return;
    }
    if (phoneNo?.length < 10) {
      setError(true);
      return;
    }

    let data: {
      // firstName: any;
      // lastName: any;
      email: any;
      countryCode: string;
      phone: string;
      about: any;
      image?: any; // Add the 'image' property as optional
      countryName: string;
      name: string;
      donationCategory: string;
      address: string;
      latitude: string;
      longitude: string;
    } = {
      // firstName: values.firstName,
      // lastName: values.lastName,
      email: values.email,
      countryCode: phoneCode?.includes("+") ? phoneCode : `+${phoneCode}`,
      countryName: countryName,
      phone: phoneNo,
      // image: profileImage || "",
      about: values.about,
      name: values.organizationName,
      donationCategory: selectedCategory,
      address: values.address,
      latitude: lat?.toString() || "76.804461",
      longitude: long?.toString() || "30.727297",
    };
    if (profileImage) {
      data.image = profileImage;
    }
    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      const res = await updateProfileMethod(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        navigate("/dashboard", { replace: true });
        successToast("Profile updated successfully");
        setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
        dispatch(
          setCredentials({
            user: res?.data || null,
            token: token || null,
          })
        );
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const handleImageUpload = async (file: RcFile, key: string) => {
    if (file?.type.includes("image")) {
      try {
        setLoading(true);
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setProfileImage(res?.data);
        }
        setLoading(false);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
      }
    } else {
      errorToast("Only images are allowed");
    }
  };

  const getCategoryDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getCategory({
        page: page,
        size: 10,
        search: "",
      }).unwrap();
      setLoading(false);

      if (res?.statusCode === 200) {
        setAllCategoryData(res?.data?.donationCategory);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeForSelectedCategory = (value: any) => {
    form.setFieldsValue({
      categoryOfTheProduct: value,
    });
    setSelectedCategory(value);

    form.validateFields(["categoryOfTheProduct"]);
  };

  useEffect(() => {
    if (userData) {
      setPhoneCode(userData?.countryCode || "+1");
      setPhoneNo(userData?.phone || "");
      setProfileImage(userData?.image || "");
      setSelectedCategory(userData?.donationCategory);
      setLocation(userData?.address);
      setValues({
        ...values,
        email: userData?.email || "",
        organizationName: userData?.name || "",
        about: userData?.about || "",
        address: userData?.address || "",
      });
      setLat(userData?.latitude);
      setLong(userData?.longitude);
      form.setFieldsValue({
        email: userData?.email,
        firstName: userData?.firstName,
        phone: userData?.phone,
        lastName: userData?.lastName,
        about: userData?.about,
        organizationName: userData?.name,
        categoryOfDonation: userData?.donationCategory,
        address: userData?.address,
      });
    }
  }, [userData]);

  useEffect(() => {
    getCategoryDetails(1);
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader heading="Update Profile" />
      <Card>
        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            style={{
              marginTop: 10,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item name="profileImage">
                  <Upload
                    beforeUpload={(file) => handleImageUpload(file, "profile")}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Profile Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {profileImage ? (
                      <img
                        className="img_profile"
                        src={profileImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="organizationName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Organization name!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Name must be at least 3 characters",
                    },
                    {
                      max: 20,
                      message: "Name must be at most 20 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Organization Name</h4>
                    <Input
                      value={values.organizationName}
                      onChange={(e) => {
                        if (isString(e.target.value)) {
                          setValues({
                            ...values,
                            organizationName: e.target.value,
                          });
                        }
                      }}
                      placeholder="Organization Name"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  // label="Username"
                  name="about"
                  rules={[
                    {
                      required: true,
                      message: "Please input your description!",
                    },
                    {
                      min: 10,
                      message: "Description must be at least 10 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>About</h4>
                    <Input
                      value={values.about}
                      onChange={(e) =>
                        setValues({ ...values, about: e.target.value })
                      }
                      placeholder="About"
                      style={{ height: 55 }}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                >
                  <div>
                    <h4>Email</h4>
                    <Input
                      disabled={userData?.isEmailVerify}
                      style={{ height: 55 }}
                      placeholder="E-mail"
                      value={values.email}
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <div>
                    <h4>Phone Number</h4>
                    <div className="phn_cs">
                      <PhoneInput
                        value={phoneCode + phoneNo}
                        enableSearch={true}
                        country={"us"}
                        placeholder="Phone number"
                        containerClass={"cntn_telCls"}
                        inputClass={"cstm_cls"}
                        buttonClass={"custm_btn"}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                        disabled={userData?.isPhoneVerify}
                        // disableCountryCode={userData?.isPhoneVerify}
                      />
                    </div>
                  </div>
                  {error && !phoneNo ? (
                    <h3 className="error_msg">
                      Please input your phone number!
                    </h3>
                  ) : null}
                  {error && phoneNo?.length && phoneNo?.length < 10 ? (
                    <h3 className="error_msg">
                      Please input valid phone number!
                    </h3>
                  ) : null}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="categoryOfDonation"
                  rules={[
                    {
                      required: true,
                      message: "Please select Category of Donation",
                    },
                  ]}
                >
                  <div>
                    <h4>Select Category</h4>
                    <Select
                      allowClear
                      maxLength={20}
                      onChange={(val) => handleChangeForSelectedCategory(val)}
                      value={selectedCategory}
                      placeholder={"Select Category"}
                      // style={{ width: 120 }}
                      optionFilterProp="label"
                      showSearch
                      filterOption={(input, option) =>
                        (
                          option?.label?.toString()?.toLowerCase() || ""
                        ).indexOf(input.toLowerCase()) !== -1
                      }
                    >
                      {allCategoryData?.map((item, index) => (
                        <Option
                          key={index}
                          value={item?._id}
                          label={item?.name}
                        >
                          {item?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>

              <Col sm={24} xs={24} md={12}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Address!",
                      whitespace: true,
                    },
                    {
                      min: 3,
                      message: "Address must be at least 3 characters",
                    },
                  ]}
                >
                  <div>
                    <h4>Address</h4>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <Input
                        onKeyDown={onKeypress}
                        placeholder="Address"
                        value={location}
                        style={{ height: 55 }}
                        onChange={(e) => setLocation(e.target.value)}
                        allowClear
                      />
                    </Autocomplete>
                  </div>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item >
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  onClick={() => setError(true)}
                >
                  Update
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Profile;
