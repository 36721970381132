import { Card, Col, Input, Row, Button, Space, Modal } from "antd";
import { ContentHeader } from "../../components";
import {
  DeleteOutlined,
  EditFilled,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Table, { ColumnsType } from "antd/es/table";

import { useNavigate } from "react-router-dom";
import {
  useDeleteCampaignMutation,
  useLazyGetAllCampaignQuery,
} from "../../services/campaign";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../helpers";
import { Campaign } from "../../types/General";

const { confirm } = Modal;

const ManageCampaign = () => {
  const navigate = useNavigate();

  const [campaign, setCampaign] = useState<Campaign[]>();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [getCampaignMethod] = useLazyGetAllCampaignQuery();
  const [deleteCampaignMethod] = useDeleteCampaignMutation();

  const showDeleteConfirm = (id: string) => {
    confirm({
      title: "Hold on",
      content: "Are you sure delete this Campaign?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns: ColumnsType<Campaign> = [
    {
      title: "Campaign Image",
      dataIndex: "image",
      key: "image",
      align: "center",
      width: "20%",
      render: (_, record) => (
        <figure>
          <img
            style={{ height: 70, width: 110, borderRadius: 10 }}
            src={record?.image}
            alt="img"
          />
        </figure>
      ),
    },
    {
      title: "Title",
      key: "name",
      dataIndex: "name",
      align: "center",
      width: "20%",
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center",
      width: "20%",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "20%",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-campaign/detail/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/manage-campaign/${record?._id}`);
            }}
          >
            <EditFilled style={{ color: "#C69320" }} />
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              showDeleteConfirm(record?._id);
            }}
          >
            <DeleteOutlined style={{ color: "#d4380d" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const handleDelete = async (id: string) => {
    try {
      const res = await deleteCampaignMethod({ campaignId: id }).unwrap();

      if (res?.statusCode === 200) {
        successToast("Campaign deleted successfully");
        getAllCampaignDetails(currentPage);
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  const getAllCampaignDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getCampaignMethod({
        page,
        query,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setCampaign(res?.data?.donation || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllCampaignDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [debouncedQuery]);

  useEffect(() => {
    getAllCampaignDetails(1);
  }, [query]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Manage Campaign"
        buttonNavigateTo="manage-campaign/add"
        buttonText="Add"
      />
      <div className="srch">
        <Card style={{ overflowX: "scroll" }}>
          <Row style={{ paddingTop: 10, paddingBottom: 10 }} gutter={16}>
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
              />
            </Col>
          </Row>

          <Table
            columns={columns}
            dataSource={campaign}
            loading={loading}
            pagination={pagination}
            rowKey={"_id"}
          />
        </Card>
      </div>
    </div>
  );
};

export default ManageCampaign;
