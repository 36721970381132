import { Button, Card, Col, Input, Row, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ContentHeader } from "../../components";
import {
  EyeOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetAllDonationsQuery,
  useLazyGetDonationCsvQuery,
} from "../../services/donations";
import { useEffect, useState } from "react";
import { errorToast } from "../../helpers";
import { DonationData } from "../../types/General";
import moment from "moment";

interface DataType {
  key: string;
  name: string;
  date: string;
  email: string;
  status: boolean;
  item: string;
  campaign: string;
}

const Donations = () => {
  const navigate = useNavigate();

  const [donationsData, setDonationsData] = useState<DonationData[]>();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [getDonationsMethod] = useLazyGetAllDonationsQuery();
  const [donationCsvMethod] = useLazyGetDonationCsvQuery();

  const columns: ColumnsType<DonationData> = [
    {
      title: "Donar Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign",
      key: "campaign",
      align: "center",
      render: (_, record) => <span>{record?.donationId?.name || ""}</span>,
    },
    {
      title: "Donated Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (_, record) => (
        <span>{moment(record?.createdAt).format("MMMM Do, YYYY") || ""}</span>
      ),
    },

    {
      title: "Donated Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (_, record) => <span>{`$${record?.amount}` || ""}</span>,
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={(e) => {
              e.preventDefault();
              navigate(`/donations/${record?._id}`);
            }}
          >
            <EyeOutlined style={{ color: "#52c41a" }} />
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      name: "John Brown",
      date: "23-12-06",
      email: "3",
      status: true,
      item: "lorem impsum ..",
      campaign: "health and care",
    },
    {
      key: "2",
      name: "Jim Green",

      email: "5",
      status: false,
      item: "lorem impsum ..",
      campaign: "health and care",
      date: "23-12-06",
    },
    {
      key: "3",
      name: "Joe Black",

      email: "6",
      status: true,
      item: "lorem impsum ..",
      campaign: "health and care",
      date: "23-12-06",
    },
  ];

  const getAllDonationDetails = async (page: number) => {
    try {
      setLoading(true);
      const res = await getDonationsMethod({
        page,
        query,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setDonationsData(res?.data?.booking || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllDonationDetails(page);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const handleExportCsv = async () => {
    try {
      setLoading(true);
      const res = await donationCsvMethod({}).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          window.open(res?.data);
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      setQuery(debouncedQuery);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [debouncedQuery]);

  useEffect(() => {
    getAllDonationDetails(1);
  }, [query]);

  return (
    <div style={{ padding: 50 }}>
      <ContentHeader
        heading="Donations"
        // buttonNavigateTo="manage-employee/add"
        // buttonText="Add Employee"
      />
      <div className="srch">
        <Card>
          <Row
            style={{ justifyContent: "space-between", padding: "10px 0px" }}
            gutter={16}
          >
            <Col xs={24} md={7}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined style={{ fontSize: "24px" }} />}
                style={{ height: 50, borderRadius: 10 }}
                value={debouncedQuery}
                onChange={(e) => setDebouncedQuery(e.target.value)}
              />
            </Col>
            <Col
              style={{
                margin: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button
                icon={<DownloadOutlined />}
                style={{
                  background: "#ECAC35",
                  height: 35,
                  color: "black",
                }}
                type="primary"
                onClick={handleExportCsv}
              >
                Export CSV
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={donationsData}
            loading={loading}
            pagination={pagination}
            rowKey={"_id"}
          />
        </Card>
      </div>
    </div>
  );
};

export default Donations;
