import { END_POINTS } from "../helpers";
import { DonationData } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  data?: {
    booking: DonationData[];
    count: number;
  };
  message: string;
};

export const donationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllDonations: builder.query<
      CommonResponseType,
      { page: number; query: string }
    >({
      query: ({ page, query }) => ({
        url: `${END_POINTS.donations}?page=${page}&size=10&search=${query}&all=false`,
        method: "GET",
      }),
    }),

    getDonationById: builder.query<
      CommonResponseType & { data: DonationData },
      { donationId: string }
    >({
      query: ({ donationId }) => ({
        url: `${END_POINTS.donations}/${donationId}`,
        method: "GET",
      }),
    }),

    getDonationCsv: builder.query<
      {
        statusCode: number;
        data: string;
      },
      unknown
    >({
      query: () => ({
        url: END_POINTS.donationExport,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllDonationsQuery,
  useLazyGetDonationByIdQuery,
  useLazyGetDonationCsvQuery,
} = donationApi;
