import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, FormInstance } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
import { useEffect, useRef, useState } from "react";
import {
  usePostForgotPasswordMutation,
  usePostVerifyOtpMutation,
} from "../../../services/auth";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
  successToast,
} from "../../../helpers";
import { setCredentials, temporaryToken } from "../../../reducer/authSlice";
import { useAppDispatch } from "../../../hooks/store";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { state } = location;

  const formRef = useRef<FormInstance>(null);

  const [OTP, setOTP] = useState("");
  const [countDown, setCountDown] = useState(59);
  const [error, setError] = useState(false);

  const [verifyOptMethod, verifyOptMutation] = usePostVerifyOtpMutation();
  const [forgotPasswordMethod, forgotPasswordMutation] =
    usePostForgotPasswordMutation();

  const onFinish = async (values: any) => {
    const fcmToken = await getFromStorage(STORAGE_KEYS.fcmToken);
    let data = {} as any;
    if (state?.countryCode) {
      data = {
        key: state.key,
        code: values.otp,
        countryCode: state?.countryCode?.includes("+")
          ? state?.countryCode
          : `+${state?.countryCode}`,
      };
      if (state?.type === "login") {
        data.deviceType = "WEB";
        data.deviceToken = fcmToken || "donation@123";
      }
      // deviceType: "WEB",
      //   deviceToken: fcmToken || "donation@123",
    } else {
      data = {
        key: state.key,
        code: values.otp,
      };
    }

    console.log(data, "datadat");

    const encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

    try {
      const res = await verifyOptMethod(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        if (state?.type === "signUp") {
          setToStorage(
            STORAGE_KEYS.tempToken,
            JSON.stringify(res?.data?.token)
          );
          setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
          dispatch(
            temporaryToken({
              tempToken: res?.data?.token || null,
            })
          );
          navigate("/profile", {
            state: {
              replace: true,
              data: res?.data,
            },
          });
        } else if (state?.type === "login") {
          if (res?.data && res?.data?.docsUploaded) {
            setToStorage(STORAGE_KEYS.token, JSON.stringify(res?.data?.token));
            setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
            dispatch(
              setCredentials({
                user: res?.data || null,
                token: res?.data?.token || null,
              })
            );
            navigate("/dashboard", { replace: true });
          } else {
            setToStorage(
              STORAGE_KEYS.tempToken,
              JSON.stringify(res?.data?.token)
            );
            setToStorage(STORAGE_KEYS.userData, JSON.stringify(res?.data));
            dispatch(
              temporaryToken({
                tempToken: res?.data?.token || null,
              })
            );
            navigate("/profile", {
              state: {
                replace: true,
                data: res?.data,
              },
            });
          }
        } else if (state?.type === "profile") {
          successToast(
            "Profile created successfully! Please wait until admin approves your document"
          );
          navigate("/", { replace: true });
        } else {
          setToStorage(
            STORAGE_KEYS.tempToken,
            JSON.stringify(res?.data?.token)
          );
          dispatch(
            temporaryToken({
              tempToken: res?.data?.token || null,
            })
          );
          navigate("/reset-password", { replace: true });
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  const handleResend = async () => {
    try {
      let body = {};

      if (state?.countryCode) {
        body = {
          key: state.key,
          countryCode: state?.countryCode?.includes("+")
            ? state?.countryCode
            : `+${state?.countryCode}`,
        };
      } else {
        body = {
          email: state.key,
        };
      }

      const encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

      const res = await forgotPasswordMethod(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        successToast("Otp sent successfully");
        setCountDown(59);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="bck_arrw_div">
          <ArrowLeftOutlined
            style={{ fontSize: 20, cursor: "pointer" }}
            className="bck_arrw"
            onClick={() => navigate(-1)}
          />
          <h3>Verify Otp</h3>
        </div>

        <div className="login_img">
          {/* <img src="/static/images/logo.png" alt="" /> */}
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          style={{
            marginTop: 50,
            justifyContent: "center",
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          ref={formRef}
        >
          <Form.Item
            className="otp_inpy"
            rules={[
              {
                required: true,
                message: "Please enter OTP!",
              },
              {
                min: 4,
                message: "",
              },
            ]}
            name="otp"
          >
            <OTPInput
              value={OTP}
              onChange={setOTP}
              style={{ height: "50px" }}
              autoFocus={false}
              OTPLength={4}
              otpType="number"
              disabled={false}
              className="otp_inpt"
              inputClassName="inpt_otpcls"
            />
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  height: 50,
                  margin: "auto",
                  color: "black",
                  fontWeight: 600,
                }}
                type="primary"
                htmlType="submit"
                loading={verifyOptMutation?.isLoading}
              >
                Verify Otp
              </Button>
            </div>
          </Form.Item>
        </Form>
        {countDown === 0 ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{
                background: "#ffffff",
                width: 160,
                height: 40,
                margin: "auto",
                fontWeight: "bold",
                color: "rgb(236, 172, 53)",
              }}
              type="text"
              loading={forgotPasswordMutation?.isLoading}
              onClick={handleResend}
            >
              Resend
            </Button>
          </div>
        ) : (
          <h5 style={{ textAlign: "center" }}>
            The verify code will expire in
            <span className="count"> 00 : {countDown}</span>
          </h5>
        )}
      </Card>
    </AuthWrapper>
  );
};

export default VerifyOtp;
