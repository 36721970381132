import { Button, Card, Col, Row, DatePicker, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetDonationByIdQuery } from "../../services/donations";
import { useEffect, useState } from "react";
import { errorToast } from "../../helpers";
import { DonationData } from "../../types/General";
import moment from "moment";

const DonarDetails = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [donationsData, setDonationsData] = useState<DonationData>();

  const [getDonationMethod] = useLazyGetDonationByIdQuery();

  const getDonationDetail = async () => {
    try {
      setLoading(true);
      if (id) {
        const res = await getDonationMethod({
          donationId: id,
        }).unwrap();
        setLoading(false);
        if (res?.statusCode === 200) {
          setDonationsData(res?.data);
        }
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getDonationDetail();
  }, []);

  return (
    <div style={{ padding: 50, gap: 20, display: "grid" }}>
      <Spin spinning={loading}>
        <Card>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Donar Details</h2>
            <Button
              style={{
                background: "#ECAC35",
                width: 100,
                height: 40,
                color: "black",
              }}
              type="primary"
              // htmlType="submit"
              onClick={() => {
                navigate("/donations", { replace: true });
              }}
            >
              Back
            </Button>
          </div>

          <div>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Campaign Image</h4>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      height: 100,
                      // margin: "auto",
                    }}
                    src={
                      donationsData?.donationId?.image ||
                      "/static/images/profile.png"
                    }
                    alt=""
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Donar Name</h4>
                <h5>{donationsData?.name || ""}</h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Campaign Name</h4>
                <h5>{donationsData?.donationId?.name || ""}</h5>
              </Col>
              <Col xs={20} md={8}>
                <h4>Donated Date</h4>
                <h5>
                  {moment(donationsData?.createdAt).format("MMMM Do, YYYY")}
                </h5>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={20} md={8}>
                <h4>Donated Amount</h4>
                <h5>${donationsData?.amount || 0} </h5>
              </Col>

              <Col xs={20} md={8}>
                <h4>Contact</h4>
                <h5>
                  {donationsData?.user?.countryCode
                    ? donationsData?.user?.countryCode +
                      " " +
                      donationsData?.user?.phone
                    : ""}
                </h5>
              </Col>
            </Row>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default DonarDetails;
