import { Route, Routes } from "react-router-dom";
import {
  Dashboard,
  Settings,
  Profile,
  ChangePassword,
  Notifications,
  EditDocuments,
  ManageCampaign,
  CampaignForm,
  Donations,
  DonarDetails,
  CampaignDetails,
} from "../pages";
import {
  ForgetPassword,
  Login,
  ProfileSetUp,
  ResetPassword,
  SignUp,
  VerifyOtp,
} from "../pages/auth";
import MainLayout from "../layout";

const RoutesNavigation = () => {
  return (
    <Routes>
      <Route path="/sign-up" element={<SignUp />}></Route>
      <Route path="/" element={<Login />}></Route>
      <Route path="/verify-otp" element={<VerifyOtp />}></Route>
      <Route path="/forgot-password" element={<ForgetPassword />}></Route>
      <Route path="/profile" element={<ProfileSetUp />}></Route>
      <Route path="/reset-password" element={<ResetPassword />}></Route>
      <Route path="/change-password" element={<ChangePassword />}></Route>
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/edit-profile"
        element={
          <MainLayout>
            <Profile />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/edit-documents"
        element={
          <MainLayout>
            <EditDocuments />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/notifications"
        element={
          <MainLayout>
            <Notifications />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/support"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-campaign"
        element={
          <MainLayout>
            <ManageCampaign />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/manage-campaign/:view"
        element={
          <MainLayout>
            <CampaignForm />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/manage-campaign/detail/:id"
        element={
          <MainLayout>
            <CampaignDetails />
          </MainLayout>
        }
      ></Route>

      <Route
        path="/donations"
        element={
          <MainLayout>
            <Donations />
          </MainLayout>
        }
      ></Route>
      <Route
        path="/donations/:id"
        element={
          <MainLayout>
            <DonarDetails />
          </MainLayout>
        }
      ></Route>
    </Routes>
  );
};

export default RoutesNavigation;
