import { Card } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useLazyGetAllNotificationsQuery } from "../../services/common";
import { errorToast } from "../../helpers";
import { NotifData } from "../../types/General";
import moment from "moment";

const Notifications = () => {
  const [notifications, setNotifications] = useState<NotifData[]>();
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowNumber, setRowNumber] = useState(0);

  const [getNotificationMethod] = useLazyGetAllNotificationsQuery();

  const columns: ColumnsType<NotifData> = [
    {
      title: "S.No.",
      dataIndex: "serial",
      key: "serial",
      width: "5%",
      align: "center",
      render: (text, record, index) => `#${rowNumber + index + 1}`,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "message",
      key: "message",
      width: "20%",
      align: "center",
    },
    {
      title: "Time",
      dataIndex: "timestamp",
      key: "timestamp",
      width: "10%",
      align: "center",
      render: (_, record) => (
        <span>
          {moment(record?.createdAt).format("MMMM Do YYYY, h:mm a") || ""}
        </span>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    getAllNotificationData(page);
    const newRowNumber = (page - 1) * 10;
    setRowNumber(newRowNumber);
  };

  const pagination = {
    pageSize: 10,
    current: currentPage,
    total: totalCount,
    onChange: handlePageChange,
  };

  const getAllNotificationData = async (page: number) => {
    try {
      setLoading(true);
      const res = await getNotificationMethod({
        page,
      }).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setNotifications(res?.data?.notification || []);
        setTotalCount(res?.data?.notificationCount);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  useEffect(() => {
    getAllNotificationData(1);
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <Card style={{ overflowX: "scroll" }}>
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <h2>Notification History</h2>
          </div>
          <Table
            columns={columns}
            dataSource={notifications}
            pagination={pagination}
            loading={loading}
          />
        </div>
      </Card>
    </div>
  );
};

export default Notifications;
