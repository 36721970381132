import AuthWrapper from "../../../layout/authWrapper";
import "../login/login.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { usePostForgotPasswordMutation } from "../../../services/auth";
import { errorToast, successToast } from "../../../helpers";
import { CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [forgotPasswordMethod, forgotPasswordMutation] =
    usePostForgotPasswordMutation();

  const onFinish = async (values: any) => {
    const data = {
      email: values.email,
    };
    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
    try {
      const res = await forgotPasswordMethod(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        navigate("/verify-otp", {
          state: {
            type: "forgot",
            key: values?.email,
          },
        });
        successToast(res?.message);
      } else {
        errorToast(res?.message);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || error?.data?.error);
    }
  };

  return (
    <AuthWrapper>
      <Card className="login_card">
        <div className="bck_arrw_div">
          <ArrowLeftOutlined
            style={{ fontSize: 20, cursor: "pointer", color: "black" }}
            className="bck_arrw"
            onClick={() => navigate(-1)}
          />
          <h3>Forgot Password</h3>
        </div>
        <div className="login_img">
          {/* <img src="/static/images/logo.png" alt="" /> */}
        </div>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          style={{
            marginTop: 50,
          }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            // label="Username"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "The input is not valid email!",
              },
            ]}
          >
            <Input
              style={{height: "50px" }}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4 }}>
            <div style={{ display: "flex" }}>
              <Button
                style={{
                  background: "#ECAC35",
                  height: 50,
                  margin: "auto",
                  color: "black",
                }}
                type="primary"
                htmlType="submit"
                loading={forgotPasswordMutation?.isLoading}
              >
                Send
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    </AuthWrapper>
  );
};

export default ForgetPassword;
