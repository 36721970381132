import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyATSsn5Homs2beUdczVq86UH1yRvLSiQwg",
  authDomain: "dasooq-678eb.firebaseapp.com",
  projectId: "dasooq-678eb",
  storageBucket: "dasooq-678eb.appspot.com",
  messagingSenderId: "805400919597",
  appId: "1:805400919597:web:80254a1cf499a1f888c2a4",
  measurementId: "G-D9G35X6242",
};
const app = initializeApp(firebaseConfig);
export default app;
