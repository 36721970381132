// development
export const API_URL: string =
  "https://dicksonapi.appgrowthcompany.com/api/v1/admin/";

export const END_POINTS = {
  login: "login",
  donationSignup: "donationSignup",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "setPassword",
  getProfile: "profile",
  updateProfile: "updateProfile",
  changePassword: "changePassword",
  mediaUpload: "upload",
  donationCategory: "donationCategory",

  // manage campaign
  campaign: "donation",

  // donation
  donations: "donationBooking",
  donationExport: "donationBookingExport",

  // notification
  notification: "donationNotification",

  // dashboard
  dashboard: "donationDashboard",

  // cms
  cms: "cms",

  // logout
  logout: "logout",
};
