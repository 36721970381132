// @ts-nocheck
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Upload,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import TextArea from "antd/es/input/TextArea";
import {
  useAddCampaignMutation,
  useEditCampaignMutation,
  useLazyGetCampaignByIdQuery,
} from "../../../services/campaign";
import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Autocomplete } from "@react-google-maps/api";
import { UploadMedia } from "../../../utils/mediaUpload";
import { errorToast, successToast } from "../../../helpers";
import { RcFile } from "antd/es/upload";
import { isString } from "../../../utils/validations";
import moment from "moment";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;

const CampaignForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { view } = useParams();

  // const sDate = undefined;
  // const eDate = undefined;

  const [campaignImage, setCampaignImage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: "",
    address: "",
  });
  const [autocomplete, setAutocomplete] = useState(null);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const [addCampaignMethod] = useAddCampaignMutation();
  const [getCampaignMethod] = useLazyGetCampaignByIdQuery();
  const [editCampaignMethod] = useEditCampaignMutation();

  const onKeypress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      return false;
    }
  };

  const onLoad = (autocompleteObj) => {
    setAutocomplete(autocompleteObj);
  };
  const onPlaceChanged = async (e, val) => {
    if (autocomplete) {
      var place = await autocomplete.getPlace();
      if (place) {
        form.setFieldsValue({
          address: place.formatted_address,
        });
        setValues({
          ...values,
          address: place.formatted_address,
        });
        // Additionally, you can set lat and long here if needed
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        setLat(lat);
        setLong(lng);
      }
    }
  };

  const onFinish = async (values: any) => {
    const { dates } = values;

    const startDate = new Date(dates[0]?.format("YYYY-MM-DD"));

    const startUtcDate = new Date(startDate.toISOString()).toISOString();

    const endDate = new Date(dates[1]?.format("YYYY-MM-DD"));

    const endUtcDate = new Date(endDate.toISOString()).toISOString();

    let data = {
      name: values.title,
      description: values.description,
      image: campaignImage,
      address: values.address,
      latitude: "76.709207",
      longitude: "30.712813",
      startTime: startUtcDate,
      endTime: endUtcDate,
      latitude: lat?.toString() || "76.804461",
      longitude: long?.toString() || "30.727297",
    };

    try {
      const body = generateEncryptedKeyBody(data) as CommonBody;
      let res;
      setLoading(true);
      if (view === "add") {
        res = await addCampaignMethod(body).unwrap();
      } else {
        res = await editCampaignMethod({ body, campaignId: view }).unwrap();
      }
      setLoading(false);
      if (res?.statusCode === 200) {
        successToast(
          `Campaign ${view === "add" ? "added" : "updated"} successfully`
        );
        navigate("/manage-campaign", { replace: true });
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      errorToast(error?.data?.message);
    }
  };

  const handleImageUpload = async (file: RcFile) => {
    if (file?.type.includes("image")) {
      try {
        const res = await UploadMedia(file);

        if (res?.statusCode === 200) {
          setCampaignImage(res?.data);
        }
      } catch (error: any) {
        console.log(error);
      }
    } else {
      errorToast("Only images are allowed");
    }

    return false;
  };

  const validateDateRange = (_: any, value: string | any[]) => {
    if (value && value.length > 1) {
      const startDate = value[0];
      const endDate = value[1];

      if (startDate && endDate && startDate.isAfter(endDate, "day")) {
        return Promise.reject("End date must be after start date");
      }
    }

    return Promise.resolve();
  };

  const getCampaignDetail = async () => {
    try {
      if (view) {
        const res = await getCampaignMethod({ campaignId: view }).unwrap();
        if (res?.statusCode === 200) {
          let startDate = dayjs(
            moment(`${res?.data?.startTime}`).format("YYYY-MM-DD")
          );
          let endDate = dayjs(
            moment(`${res?.data?.endTime}`).format("YYYY-MM-DD")
          );

          setValues({
            ...values,
            name: res?.data?.name,
            description: res?.data?.description,
            address: res?.data?.address,
            // image: res?.data?.image
          });
          setCampaignImage(res?.data?.image);
          form.setFieldsValue({
            image: res?.data?.image,
            title: res?.data?.name,
            address: res?.data?.address,
            description: res?.data?.description,
            dates: [startDate, endDate],
            address: res?.data?.address,
          });
        }
      }
    } catch (error: any) {
      console.log(error);
      errorToast(error?.data?.message);
    }
  };

  useEffect(() => {
    if (view !== "add") {
      getCampaignDetail();
    }
  }, []);

  return (
    <div style={{ padding: 50 }}>
      <Card>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <h2>{view === "add" ? "Add Campaign" : "Edit Campaign"}</h2>
          <Button
            style={{
              background: "#ECAC35",
              width: 100,
              height: 40,
              color: "black",
            }}
            type="primary"
            // htmlType="submit"
            onClick={() => {
              navigate("/manage-campaign", { replace: true });
            }}
          >
            Back
          </Button>
        </div>

        <div>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            style={{
              maxWidth: "80%",
              marginTop: 20,
            }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            //   onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "This field is compulsory",
                    },
                  ]}
                  name="image"
                >
                  <Upload
                    beforeUpload={handleImageUpload}
                    showUploadList={false}
                    className="upload_imgD"
                    accept="image/*"
                  >
                    <div>
                      <h4>Upload Image</h4>
                      <Button icon={<UploadOutlined />}>Click to upload</Button>
                    </div>
                    {campaignImage ? (
                      <img
                        style={{
                          height: 110,
                          width: 110,
                          borderRadius: "50%",
                          marginTop: 20,
                        }}
                        src={campaignImage}
                        alt="profile"
                      />
                    ) : (
                      <></>
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Title</h4>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input Campaign title!",
                      whitespace: true,
                    },
                    {
                      min: 5,
                      message: "Title must be at least 5 characters",
                    },
                  ]}
                >
                  <div>
                    <Input
                      placeholder="Title"
                      style={{ height: 55 }}
                      value={values.name}
                      onChange={(e) => {
                        if (isString(e.target.value)) {
                          setValues({ ...values, name: e.target.value });
                        }
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <h4>Address</h4>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input address!",
                      whitespace: true,
                    },
                    {
                      min: 5,
                      message: "Address must be at least 5 characters",
                    },
                  ]}
                >
                  <div>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <Input
                        placeholder="Address"
                        style={{ height: 55 }}
                        value={values.address}
                        allowClear
                        onKeyDown={onKeypress}
                        onChange={(e) => {
                          setValues({ ...values, address: e.target.value });
                        }}
                      />
                    </Autocomplete>
                  </div>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} md={12}>
                <h4>Description</h4>
                <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input Campaign description!",
                      whitespace: true,
                    },
                    {
                      min: 20,
                      message: "Description must be at least 20 characters",
                    },
                  ]}
                >
                  <div>
                    <TextArea
                      rows={8}
                      placeholder="Description"
                      value={values.description}
                      onChange={(e) => {
                        setValues({ ...values, description: e.target.value });
                      }}
                    />
                  </div>
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <h4>Start End Date</h4>
                <Form.Item
                  name="dates"
                  rules={[
                    { required: true, message: "Please select a date range" },
                    { validator: validateDateRange },
                  ]}
                  validateTrigger={["onChange", "onBlur"]}
                >
                  <RangePicker
                    onChange={(val) => console.log(val)}
                    style={{ width: "100%", height: 55 }}
                    disabledDate={(d) => d && d.isBefore(new Date(), "day")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#ECAC35",
                    width: 200,
                    height: 50,
                    margin: "auto",
                    color: "black",
                  }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default CampaignForm;
